.home {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    .img-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    img {
      position: absolute;
      min-height: 100%;
      min-width: 360px;

      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .turn-bg {
    position: absolute;
    top: 55px;
    right: 50px;
    font-size: 12px;
    font-weight: 700;
    color: #20619C;
    animation: blink 1s linear infinite;
    img {
      margin-left: 4px;
      width: 15px;
      height: 18px;
    }
    @keyframes blink{
      0%{opacity: 0;}
      50%{opacity: .5;}
      100%{opacity: 1;}
    }
    @-webkit-keyframes blink{
      0%{opacity: 0;}
      50%{opacity: .5;}
      100%{opacity: 1;}
    }
  }

  .ic-logo-text {
    position: absolute;
    top: 23px;
    left: 22px;
    width: 39px;
    height: 13px;
    img {
      width: 39px;
      height: 13px;
    }
  }

  .img-cloud {
    position: relative;
    padding-top: 132px;
    margin-left: auto;
    margin-right: auto;
    width: 207px;
    height: 87px;
    img {
      width: 207px;
      height: 87px;
    }
    .abs-title {
      position: absolute;
      top: 168px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      color: #20619C;
      p {
        margin-bottom: 6px;
      }
    }
  }

  .introduction {
    margin-top: 132px;
    margin-left: auto;
    margin-right: auto;
    p {
      margin-left: auto;
      margin-right: auto;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      color: #20619C;
      line-height: 1.5;
    }
    p.gamja-text {
      font-weight: 400;
      font-size: 35px;
      line-height: 52px;
      color: #05111D;
      font-family: 'GamjaFlower';
      span.blue {
        color: #0666BC;
      }
    }
  }

  .go-to-test-btn {
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 196px;
    height: 64px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #1E4D94;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
  }

  .intro-tap-to-next {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    .img-yongwang {
      position: absolute;
      width: 190px;
      height: 278px;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      animation: 0.5s ease-in 0s enter;
      -webkit-animation: 0.5s ease-in 0s enter;
      @-webkit-keyframes enter { from { bottom: -278px; } to { bottom: 0; }  }
      @keyframes enter { from { bottom: -278px; } to { bottom: 0; }  }
      img {
        width: 190px;
        height: 278px;
      }
    }
    .intro-desc {
      padding-top: 135px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      p {
        line-height: 1.8;
        font-size: 14px;
        font-weight: 700;
        color: #20619C;
      }
    }
    .continue {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        margin-right: 3px;
        color: #1C4366;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
      }
      img {
        width: 26px;
        height: 12px;
      }
    }
  }
}