.question-transition-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.question-wrapper {
  padding-top: 22px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    .img-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    img {
      position: absolute;
      min-height: 100%;
      min-width: 360px;

      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .next-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    opacity: 0;
    img {
      position: fixed;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .continue {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-right: 3px;
      color: #1C4366;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
    }
    img {
      width: 26px;
      height: 12px;
    }
  }
}
.question-description,
.question-transition {
  font-weight: 700;
  font-size: 18px;
  line-height: 34px;
  text-align: center;
  line-break: auto;
  white-space: pre-wrap;
}

.option-btn {
  &:first-child {
    margin-top: 36px;  
  }
  width: 314px;
  height: 64px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  background-color: #08162a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
}

.question-header {
  width: calc(100% - 46px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back-btn {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 14px;
      height: 12px;
    }
  }
  .current-page {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
  }
}

.question-1 {
  .question-description {
    margin-top: 210px;
    color: #fff;
  }
  .current-page {
    color: #fff;
  }
  .options-wrapper {
    position: absolute;
    bottom: 108px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.question-2 {
  .question-description {
    margin-top: 240px;
    margin-bottom: 14px;
    color: #fff;
  }
  .current-page {
    color: #fff;
  }
}

.question-3 {
  .question-description {
    margin-top: 200px;
    margin-bottom: 14px;
    color: #344592;
  }
  .current-page {
    color: #344592;
  }
}

.question-4 {
  .question-transition {
    margin-top: 130px;
    color: #35497e;
  }
  .continue p {
    color: #35497e;
  }
}
.question-5 {
  .question-description {
    margin-top: 26px;
    margin-bottom: 14px;
    color: #344592;
  }
  .current-page {
    color: #344592;
  }
}

.question-6 {
  .question-description {
    width: 108px;
    height: 96px;
    text-align: left;
    position: absolute;
    top: 180px;
    left: 24px;
    color: #5b3e11;
    line-height: 30px;
  }
  .options-wrapper {
    position: absolute;
    bottom: 108px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .current-page {
    color: #5b3e11;
  }
}

.question-7 {
  .question-description {
    margin-top: 18px;
    margin-bottom: 23px;
    color: #fff;
  }
  .current-page {
    color: #fff;
  }
}

.question-8 {
  .question-transition {
    margin-top: 130px;
    color: #fff;
  }
  .continue p {
    color: #fff;
  }
}

.question-9 {
  .question-description {
    margin-top: 21px;
    margin-bottom: 14px;
    color: #fff;
  }
  .current-page {
    color: #fff;
  }
}

.question-10 {
  .question-description {
    margin-top: 18px;
    margin-bottom: 23px;
    color: #12293d;
  }
  .current-page {
    color: #12293d;
  }
}

.question-11 {
  .question-description {
    margin-top: 290px;
    margin-bottom: 14px;
    color: #35497e;
  }
  .current-page {
    color: #35497e;
  }
}

.question-12 {
  .question-description {
    margin-top: 24px;
    margin-bottom: 14px;
    color: #08162b;
  }
  .current-page {
    color: #08162b;
  }
}

.question-13 {
  overflow: hidden;
  .question-transition {
    margin-top: 84px;
    color: #4c69b5;
    line-break: auto;
    white-space: pre-wrap;
  }
  .continue p {
    color: #4c69b5;
  }
  .transition-images {
    .transition-image-0 {
      position: absolute;
      bottom: 47px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      animation: 0.7s ease-in 0s enter;
      -webkit-animation: 0.7s ease-in 0s enter;
      @-webkit-keyframes enter { from { bottom: -200px; } to { bottom: 47px; }  }
      @keyframes enter { from { bottom: -200px; } to { bottom: 47px; }  }
      img {
        width: 212px;
        height: 263px;
      }
    }
    .transition-image-1 {
      position: absolute;
      bottom: -168px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: center;
      animation: 0.7s ease-in 0s next-enter;
      -webkit-animation: 0.7s ease-in 0s next-enter;
      @-webkit-keyframes next-enter { from { bottom: -415px; } to { bottom: -168px; } }
      @keyframes next-enter { from { bottom: -415px; } to { bottom: -168px; } }
      img {
        width: 267px;
        height: 267px;
      }
    }
  }  
}

.question-14 {
  .question-description {
    margin-top: 183px;
    margin-bottom: 14px;
    color: #35497e;
  }
  .current-page {
    color: #35497e;
  }
}

.question-15 {
  .question-description {
    margin-top: 126px;
    margin-bottom: 14px;
    color: #fff;
  }
  .current-page {
    color: #fff;
  }
}

.question-16 {
  .question-description {
    padding-top: 150px;
    margin-bottom: 36px;
    color: #fff;
  }
  .jar {
    margin-left: auto;
    margin-right: auto;
    width: 235px;
    height: 236px;
    position: relative;
    overflow: hidden;
    > div {
      position: absolute;
      top: 0;
      animation: 0.5s ease-in 0s jarenter;
      -webkit-animation: 0.5s ease-in 0s jarenter;
      @-webkit-keyframes jarenter { from { top: 236px; } to { top: 0; }  }
      @keyframes jarenter { from { top: 236px; } to { top: 0; }  }
    }
    img {
      width: 235px;
      height: 236px;
    }
  }
  .submit-btn {
    margin-top: 44px;
    margin-left: auto;
    margin-right: auto;
    width: 262px;
    height: 64px;
    border-radius: 50px;
    background-color: #1486ba;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
