.loading {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: #8CC1DF;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    .dots {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 36px;
    }
    .dot {
      animation: showHideDot 2s ease-in-out infinite;
      opacity: 0;
      &.one { animation-delay: 0.2s; }
      &.two { animation-delay: 0.4s; }
      &.three { animation-delay: 0.6s; }

      @-webkit-keyframes showHideDot {
        0% { opacity: 0; }
        50% { opacity: 1; }
        60% { opacity: 1; }
        100% { opacity: 0; }
      }
      @keyframes showHideDot {
        0% { opacity: 0; }
        50% { opacity: 1; }
        60% { opacity: 1; }
        100% { opacity: 0; }
      }
    }
  }
}