.result-wrapper {
  padding: 28px 28px 60px;
  width: 100%;
  height: 100%;
  position: relative;
  .lucid-text-logo {
    width: 35px;
    height: 12px;
    img {
      width: 35px;
      height: 12px;
    }
  }
  .character-wrapper {
    width: 267px;
    height: 180px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .result-description-wrapper {
    margin-top: 20px;
    padding: 24px;
    width: 100%;
    height: 345px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 23px;
    .modifier {
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      line-height: 20px;
    }
    .name {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      line-height: 27px;
    }
    .description {
      font-size: 13px;
      text-align: center;
      line-height: 22px;
      margin-top: 15px;
      line-break: auto;
      white-space: pre-wrap;
    }
  }
  .soulsound-wrapper {
    margin-top: 34px;
    text-align: center;
    .sound-item {
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 22px;
      width: 300px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      cursor: pointer;
    }
    .play-icon {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: #363636;
      display: flex;
      align-items: center;
      justify-content: center;
      .ic-play {
        margin-left: 2px;
        width: 12px;
        height: 14px;
      }
      .ic-stop {
        width: 14px;
        height: 14px;
      }
    }
    .sound-name {
      width: 268px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 23px;
      color: #fff;
    }
  }
  .friend-wrapper {
    margin-top: 32px;
    text-align: center;
    .friend-img {
      margin-top: 15px;
      width: 115px;
      height: 78px;
      margin-left: auto;
      margin-right: auto;
    }
    .friend-name {
      margin-top: 7px;
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      line-height: 23px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
  }
  .share {
    margin-top: 34px;
    text-align: center;
    .icons {
      margin-top: 24px;
      margin-left: auto;
      margin-right: auto;
      width: 78px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
  .last-gift {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 258px;
    height: 54px;
    border-radius: 27px;
    background-color: #212121;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .copy-popup {
    position: absolute;
    bottom: 36px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 36px;
    border-radius: 18px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  .copy-popup.show {
    opacity: 1;
  }
  .copy-popup.hidden {
    opacity: 0;
  }
}

.result-wrapper.infp {
  background: url("../../assets/images/results/infp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/infp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #fff;
  }
  .title {
    color: #fff;
  }
  .sound-item {
    background-color: #0E3979;
  }
  .play-icon {
    background-color: #19638D;
  }
  .friend-name {
    color: #FFAB49;
  }
}
.result-wrapper.infj {
  background: url("../../assets/images/results/infj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/infj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #8F6423;
  }
  .title {
    color: #634F09;
  }
  .sound-item {
    background-color: #BB8431;
  }
  .play-icon {
    background-color: #E2A13F;
  }
  .friend-name {
    color: #320967;
  }
}
.result-wrapper.intp {
  background: url("../../assets/images/results/intp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/intp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #20886F;
  }
  .title {
    color: #0B6B54;
  }
  .sound-item {
    background-color: #16A79E;
  }
  .play-icon {
    background-color: #26BDB4;
  }
  .friend-name {
    color: #113557;
  }
}
.result-wrapper.intj {
  background: url("../../assets/images/results/intj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/intj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #C55606;
  }
  .title {
    color: #601308;
  }
  .sound-item {
    background-color: #C14B09;
  }
  .play-icon {
    background-color: #DF8A4C;
  }
  .friend-name {
    color: #A95F1A;
  }
}
.result-wrapper.isfp {
  background: url("../../assets/images/results/isfp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/isfp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #9E692B;
  }
  .title {
    color: #65470D;
  }
  .sound-item {
    background-color: #C18237;
  }
  .play-icon {
    background-color: #E19F3B;
  }
  .friend-name {
    color: #403932;
  }
}
.result-wrapper.isfj {
  background: url("../../assets/images/results/isfj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/isfj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #845CC2;
  }
  .title {
    color: #42208E;
  }
  .sound-item {
    background-color: #7C51DE;
  }
  .play-icon {
    background-color: #AB82EA;
  }
  .friend-name {
    color: #1E4F98;
  }
}
.result-wrapper.istp {
  background: url("../../assets/images/results/istp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/istp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #5C7349;
  }
  .title {
    color: #46761E;
  }
  .sound-item {
    background-color: #83B85A;
  }
  .play-icon {
    background-color: #ADDA88;
  }
  .friend-name {
    color: #256350;
  }
}
.result-wrapper.istj {
  background: url("../../assets/images/results/istj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/istj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #796E0D;
  }
  .title {
    color: #794A13;
  }
  .sound-item {
    background-color: #A07E3C;
  }
  .play-icon {
    background-color: #DBA439;
  }
  .friend-name {
    color: #172E59;
  }
}
.result-wrapper.enfp {
  background: url("../../assets/images/results/enfp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/enfp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #324B8C;
  }
  .title {
    color: #0E4D71;
  }
  .sound-item {
    background-color: #2072BE;
  }
  .play-icon {
    background-color: #4C90DF;
  }
  .friend-name {
    color: #773021;
  }
}
.result-wrapper.enfj {
  background: url("../../assets/images/results/enfj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/enfj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #9E692B;
  }
  .title {
    color: #724511;
  }
  .sound-item {
    background-color: #CE844E;
  }
  .play-icon {
    background-color: #ECB186;
  }
  .friend-name {
    color: #27547D;
  }
}
.result-wrapper.entp {
  background: url("../../assets/images/results/entp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/entp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #2B30AE;
  }
  .title {
    color: #1D1C9E;
  }
  .sound-item {
    background-color: #5359E8;
  }
  .play-icon {
    background-color: #878BFB;
  }
  .friend-name {
    color: #87480E;
  }
}
.result-wrapper.entj {
  background: url("../../assets/images/results/entj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/entj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #14765E;
  }
  .title {
    color: #08604B;
  }
  .sound-item {
    background-color: #15A784;
    
  }
  .play-icon {
    background-color: #25CDA5;
  }
  .friend-name {
    color: #0D5E8C;
  }
}
.result-wrapper.esfp {
  background: url("../../assets/images/results/esfp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/esfp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #074D75;
  }
  .title {
    color: #184669;
  }
  .sound-item {
    background-color: #4154A9;
  }
  .play-icon {
    background-color: #4F85C3;
  }
  .friend-name {
    color: #166423;
  }
}
.result-wrapper.esfj {
  background: url("../../assets/images/results/esfj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/esfj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #0E4267;
  }
  .title {
    color: #152361;
  }
  .sound-item {
    background-color: #1D51B4;
  }
  .play-icon {
    background-color: #5576EC;
  }
  .friend-name {
    color: #7C430D;
  }
}
.result-wrapper.estp {
  background: url("../../assets/images/results/estp/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/estp/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #23488F;
  }
  .title {
    color: #0F2F6C;
  }
  .sound-item {
    background-color: #1558BC;
  }
  .play-icon {
    background-color: #3793C7;
  }
  .friend-name {
    color: #270967;
  }
}
.result-wrapper.estj {
  background: url("../../assets/images/results/estj/bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .character-wrapper {
    background: url("../../assets/images/results/estj/character.png") no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .modifier, .name {
    color: #238F75;
  }
  .title {
    color: #0F6C56;
  }
  .sound-item {
    background-color: #24AB8B;
  }
  .play-icon {
    background-color: #37C7A4;
  }
  .friend-name {
    color: #09673A;
  }
}

.friend-img.esfp {
  background: url("../../assets/images/results/esfp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.esfj {
  background: url("../../assets/images/results/esfj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.estp {
  background: url("../../assets/images/results/estp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.estj {
  background: url("../../assets/images/results/estj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.enfp {
  background: url("../../assets/images/results/enfp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.enfj {
  background: url("../../assets/images/results/enfj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.entp {
  background: url("../../assets/images/results/entp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.entj {
  background: url("../../assets/images/results/entj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.isfp {
  background: url("../../assets/images/results/isfp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.isfj {
  background: url("../../assets/images/results/isfj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.istp {
  background: url("../../assets/images/results/istp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.istj {
  background: url("../../assets/images/results/istj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.infp {
  background: url("../../assets/images/results/infp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.infj {
  background: url("../../assets/images/results/infj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.intp {
  background: url("../../assets/images/results/intp/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.friend-img.intj {
  background: url("../../assets/images/results/intj/character.png") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}