.bgm-box {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 76px;
  height: 26px;
  border: 2px solid #1E4D94;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  color: #1E4D94;
  background-color: transparent;
}