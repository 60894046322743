@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');
@import "default";
@import "fonts";
@import "app";

@import "components/pageview";
@import "components/question";
@import "components/loading";
@import "components/bgm_button";
@import "pages/home";
@import "pages/travel";
@import "pages/result";
@import "pages/last_gift";
