.lastgift {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    .img-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
    img {
      position: absolute;
      min-height: 100%;
      min-width: 360px;

      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .ic-logo-circle {
    margin-left: auto;
    margin-right: auto;
    padding-top: 144px;
    width: 79px;
    height: 223px;
    img {
      width: 79px;
      height: 79px;
    }
  }

  .ic-logo-text {
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px;
    width: 72px;
    height: 24px;
    img {
      width: 72px;
      height: 24px;
    }
  }
  .logo-area {
    margin-bottom: 14px;
  }
  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    color: #274185;
    text-align: center;
  }
  .mail-address-input-wrapper {
    margin-top: 30px;
    width: 280px;
    height: 46px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    .mail-address {
      width: 232px;
      height: 46px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      font-size: 12px;
      color: #18356D;
      font-weight: 700;
      line-height: 20px;
    }
    .send-btn {
      width: 48px;
      height: 46px;
      background-color: #2275C0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      line-height: 20px;
      text-align: center;
    }
    .send-text {
      width: 48px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dots {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 36px;
    margin-top: -24px;
  }
  .dot {
    animation: showHideDot 2s ease-in-out infinite;
    opacity: 0;
    &.one { animation-delay: 0.2s; }
    &.two { animation-delay: 0.4s; }
    &.three { animation-delay: 0.6s; }
  
    @-webkit-keyframes showHideDot {
      0% { opacity: 0; }
      50% { opacity: 1; }
      60% { opacity: 1; }
      100% { opacity: 0; }
    }
    @keyframes showHideDot {
      0% { opacity: 0; }
      50% { opacity: 1; }
      60% { opacity: 1; }
      100% { opacity: 0; }
    }
  }

  .gift-explanation {
    margin-top: 24px;
    font-size: 14px;
    color: #0B285F;
    line-height: 25px;
    font-weight: 500;
    line-break: auto;
    white-space: pre-wrap;
    text-align: center;
  }
  .dontworry {
    margin-top: 20px;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    color: #142A53;
    line-break: auto;
    white-space: pre-wrap;
  }
  .error-popup {
    position: absolute;
    bottom: 36px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 36px;
    border-radius: 18px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  .error-popup.show {
    opacity: 1;
  }
  .error-popup.hidden {
    opacity: 0;
  }
  .success-popup {
    position: absolute;
    bottom: 36px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 36px;
    border-radius: 18px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  .success-popup.show {
    opacity: 1;
  }
  .success-popup.hidden {
    opacity: 0;
  }
  .link-wrapper {
    padding-bottom: 36px;
  }
  .link-to-start {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    margin-left: auto;
    margin-right: auto;
    width: 258px;
    height: 36px;
    border-radius: 27px;
    background-color: #212121;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
}
